import React, { useState, useRef, useEffect } from "react";
import { Send, MessageCircle, Car, ExternalLink } from "lucide-react";

const API_URL = process.env.REACT_APP_API_URL;

console.log("API URL:", API_URL); // Vérifier si l'URL est bien correcte


//Translation
const translations = {
  nl: {
    welcome: {
      initial: "Welkom! Hoe kan ik u helpen?\nKies een van die opties:",
      return: "Waarmee kan ik u helpen?\nKies een optie:",
    },
    menu: {
      sellCar: "Mijn auto verkopen",
      airco: "Airco bijvullen",
      otherQuestion: "Andere vraag?",
    },
    vehicleTypes: {
      prompt: "Welk type voertuig wilt u verkopen?",
      car: "Auto",
      van: "Bestelwagen",
      truck: "Vrachtwagen",
      back: "Terug",
    },
    aircoSection: {
      title: "Hier zijn onze airco prijzen:",
      buttonText: "Bekijk airco prijzen",
      url: "https://www.aankoopvanautos.be/goedkoop-airco-bijvullen/",
    },
    forms: {
      title: "Vul ons online formulier in voor uw",
      buttonText: "Naar het formulier",
      url: "https://aankoopvanautos.be/VerkoopUwAuto/",
    },
    conversation: {
      listening: "Ik luister.",
      moreQuestions: "Heb je nog vragen?",
      whatToKnow: "Wat wilt u weten?",
      goodbye: "Bedankt voor uw bezoek. Tot ziens!",
      closingTimer: "Deze conversatie zal over 2 minuten worden afgesloten",
      error: "Er is een fout opgetreden. Probeer het later opnieuw.",
      textOnly: "Ik kan alleen tekstberichten verwerken.",
      serviceOnly: "Ik kan alleen informatie geven over onze diensten.",
      yes: "Ja",
      no: "Nee",
    },
    location: {
      address:
        "Wij zijn gevestigd in Kuurne, België. U kunt ons vinden op:\nBrugsesteenweg 285\n8520 KUURNE\nBelgië",
    },
    hours: {
      schedule:
        "Onze openingsuren zijn:\nMaandag tot vrijdag: 9:00 - 18:00\nZaterdag: 10:00 - 16:00\nEnkel op Afspraak\nZondag: Gesloten",
    },
    placeholder: {
      input: "Typ uw bericht...",
    },
  },
  fr: {
    welcome: {
      initial:
        "Bienvenue ! Comment puis-je vous aider ?\nChoisissez une option :",
      return: "Comment puis-je vous aider ?\nChoisissez une option :",
    },
    menu: {
      sellCar: "Vendre ma voiture",
      airco: "Recharge climatisation",
      otherQuestion: "Autre question ?",
    },
    vehicleTypes: {
      prompt: "Quel type de véhicule souhaitez-vous vendre ?",
      car: "Voiture",
      van: "Camionnette",
      truck: "Camion",
      back: "Retour",
    },
    aircoSection: {
      title: "Voici nos prix pour la climatisation :",
      buttonText: "Voir les prix clim",
      url: "https://www.aankoopvanautos.be/goedkoop-airco-bijvullen/",
    },
    forms: {
      title: "Remplissez notre formulaire pour votre",
      buttonText: "Vers le formulaire",
      url: "https://aankoopvanautos.be/VerkoopUwAuto/",
    },
    conversation: {
      listening: "Je vous écoute.",
      moreQuestions: "Avez-vous d'autres questions ?",
      whatToKnow: "Que souhaitez-vous savoir ?",
      goodbye: "Merci de votre visite. Au revoir !",
      closingTimer: "Cette conversation se terminera dans 2 minutes",
      error: "Une erreur est survenue. Veuillez réessayer plus tard.",
      textOnly: "Je ne peux traiter que les messages texte.",
      serviceOnly: "Je ne peux donner que des informations sur nos services.",
      yes: "Oui",
      no: "Non",
    },
    location: {
      address:
        "Nous sommes situés à Kuurne, Belgique. Vous pouvez nous trouver à :\nBrugsesteenweg 285\n8520 KUURNE\nBelgique",
    },
    hours: {
      schedule:
        "Nos horaires d'ouverture sont :\nLundi à vendredi : 9h00 - 18h00\nSamedi : 10h00 - 16h00\nUniquement sur Rendez-vouz\nDimanche : Fermé",
    },
    placeholder: {
      input: "Votre message...",
    },
  },
  en: {
    welcome: {
      initial: "Welcome! How can I help you?\nChoose an option:",
      return: "How can I help you?\nChoose an option:",
    },
    menu: {
      sellCar: "Sell my car",
      airco: "AC refill",
      otherQuestion: "Other question?",
    },
    vehicleTypes: {
      prompt: "What type of vehicle do you want to sell?",
      car: "Car",
      van: "Van",
      truck: "Truck",
      back: "Back",
    },
    aircoSection: {
      title: "Here are our AC service prices:",
      buttonText: "View AC prices",
      url: "https://www.aankoopvanautos.be/goedkoop-airco-bijvullen/",
    },
    forms: {
      title: "Fill out our form for your",
      buttonText: "Go to form",
      url: "https://aankoopvanautos.be/VerkoopUwAuto/",
    },
    conversation: {
      listening: "I'm listening.",
      moreQuestions: "Do you have any other questions?",
      whatToKnow: "What would you like to know?",
      goodbye: "Thank you for your visit. Goodbye!",
      closingTimer: "This conversation will close in 2 minutes",
      error: "An error occurred. Please try again later.",
      textOnly: "I can only process text messages.",
      serviceOnly: "I can only provide information about our services.",
      yes: "Yes",
      no: "No",
    },
    location: {
      address:
        "We are located in Kuurne, Belgium. You can find us at:\nBrugsesteenweg 285\n8520 KUURNE\nBelgium",
    },
    hours: {
      schedule:
        "Our opening hours are:\nMonday to Friday: 9:00 AM - 6:00 PM\nSaturday: 10:00 AM - 4:00 PM\nOnly on Appointment\nSunday: Closed",
    },
    placeholder: {
      input: "Type your message...",
    },
  },
};

// Fonction améliorée de détection de langue
export const detectLanguage = (text) => {
  const langPatterns = {
    fr: /(bonjour|salut|merci|voiture|je|vous|comment|prix|vendre|acheter)/i,
    en: /(hello|hi|thanks|car|please|how|price|sell|buy|what|where)/i,
    nl: /(hallo|bedankt|auto|dag|prijs|verkopen|kopen|waar|hoe|wat)/i,
  };

  // Compter les correspondances pour chaque langue
  const scores = Object.entries(langPatterns).reduce((acc, [lang, pattern]) => {
    const matches = (text.match(pattern) || []).length;
    acc[lang] = matches;
    return acc;
  }, {});

  // Trouver la langue avec le plus de correspondances
  const detectedLang = Object.entries(scores).reduce((a, b) =>
    scores[a] > scores[b] ? a : b
  )[0];

  return detectedLang || "nl"; // Néerlandais par défaut
};

const FloatingChatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // Détecter l'orientation et la taille de l'écran
  useEffect(() => {
    const handleResize = () => {
      if (
        window.innerWidth <= 320 ||
        window.matchMedia("(orientation: landscape) and (max-height: 500px)")
          .matches
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  /* const handleOpen = () => {
    if (
      window.innerWidth <= 320 ||
      window.matchMedia("(orientation: landscape) and (max-height: 500px)")
        .matches
    ) {
      window.open("https://aankoopvanautos.be/contact", "_blank");
      return;
    }
    setIsOpen(true);
  }; */

  useEffect(() => {
    const handleResize = () => {
      // Ne pas fermer complètement le chatbot lors de la rotation
      if (
        window.innerWidth <= 320 ||
        window.matchMedia("(orientation: landscape) and (max-height: 500px)")
          .matches
      ) {
        // Plutôt que de fermer, changer l'affichage ou cacher certains éléments
        document.querySelector(".floating-chatbot").classList.add("minimize");
      } else {
        document
          .querySelector(".floating-chatbot")
          .classList.remove("minimize");
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen ? (
        <div
          className={`relative transition-all duration-300 ${
            isClosing
              ? "animate-slideDown opacity-0"
              : "animate-slideUp opacity-100"
          }`}
        >
          <div className="absolute -top-2 right-2 z-10">
            <button
              onClick={handleClose}
              className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition-colors"
            >
              <div className="w-4 h-0.5 bg-gray-600 rounded-full" />
            </button>
          </div>
          <div className="transform-gpu">
            <MainChatbot />
          </div>
        </div>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          className="bg-blue-600 w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 rounded-full shadow-lg flex items-center justify-center hover:bg-blue-700 transition-all transform hover:scale-110 animate-bounce"
        >
          <MessageCircle className="w-6 h-6 sm:w-7 sm:h-7 md:w-8 md:h-8 text-white" />
        </button>
      )}
    </div>
  );
};

const LoadingDots = () => {
  return (
    <div className="flex justify-start">
      <div className="bg-gray-100 rounded-lg p-3 flex items-center space-x-1">
        <div
          className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
          style={{ animationDelay: "0s" }}
        ></div>
        <div
          className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
          style={{ animationDelay: "0.1s" }}
        ></div>
        <div
          className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
          style={{ animationDelay: "0.2s" }}
        ></div>
      </div>
    </div>
  );
};

const MainChatbot = () => {
  const messagesEndRef = useRef(null);
  const timerRef = useRef(null); // Pour gérer le timer
  const [currentLang, setCurrentLang] = useState("nl");
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: translations.nl.welcome.initial,
      showOptions: true,
    },
  ]);
  const [inputText, setInputText] = useState("");
  const [currentStep, setCurrentStep] = useState("initial");
  const [countdown, setCountdown] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Get current translations
  const t = translations[currentLang];

  // Handle language change
  const handleLanguageChange = (newLang) => {
    setCurrentLang(newLang);
    // Update welcome message if it's the only message
    if (messages.length === 1) {
      setMessages([
        {
          role: "assistant",
          content: translations[newLang].welcome.initial,
          showOptions: true,
        },
      ]);
    }
  };

  // Fonction de scroll améliorée
  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Petit délai pour assurer que le contenu est rendu
  };

  // Utiliser useEffect pour le scroll automatique
  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Se déclenche à chaque changement des messages

  const validateResponse = (message) => {
    const forbiddenPhrases = [
      "andere bedrijven",
      "via hun website",
      "upload",
      "file",
      "svg",
      "bestand",
      "document",
      "bijlage",
      "attachment",
      "stuur een",
      "send a",
      "fichiers disponibles",
      "langue arabe",
      "zoals",
      "Les fichiers actuels",
    ];

    const containsForbiddenWord = forbiddenPhrases.some((phrase) =>
      message.toLowerCase().includes(phrase)
    );

    const uploadPatterns = [
      /upload/i,
      /verstuur.*(bestand)/i,
      /send.*(file)/i,
      /stuur.*(document)/i,
      /attach/i,
      /bijvoeg/i,
    ];

    return (
      !containsForbiddenWord &&
      !uploadPatterns.some((pattern) => pattern.test(message))
    );
  };

  // Fonction pour réinitialiser le chat
  const resetChat = () => {
    setMessages([
      {
        role: "assistant",
        content: t.welcome.initial,
        showOptions: true,
      },
    ]);
    setCurrentStep("initial");
    setInputText("");
    setCountdown(null);
  };

  // Fonction pour démarrer le timer de réinitialisation
  const startResetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Démarrer le compte à rebours à 180 secondes (3 minutes)
    setCountdown(120);

    // Mettre à jour le compte à rebours chaque seconde
    const countdownInterval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Ajouter le message de timer
    setMessages((prev) => [
      ...prev,
      {
        role: "system",
        content: t.conversation.closingTimer,
        isTimer: true,
        allowInput: true,
      },
    ]);

    // Définir le timer de réinitialisation
    timerRef.current = setTimeout(() => {
      clearInterval(countdownInterval);
      resetChat();
    }, 2 * 60 * 1000); // 2 minutes
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const options = {
    initial: [
      { id: "1", text: t.menu.sellCar },
      { id: "2", text: t.menu.airco },
      { id: "3", text: t.menu.otherQuestion, isCustom: true },
    ],
    vehicleType: [
      { id: "auto", text: t.vehicleTypes.car },
      { id: "bestelwagen", text: t.vehicleTypes.van },
      { id: "vrachtwagen", text: t.vehicleTypes.truck },
      { id: "back", text: t.vehicleTypes.back, isBack: true },
    ],
    askMore: [
      { id: "yes", text: t.conversation.yes },
      { id: "no", text: t.conversation.no },
    ],
  };

  const LinkWithButton = ({ url, text, icon }) => (
    <div className="mt-2">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition-colors"
      >
        {text}
        {icon === "ExternalLink" && <ExternalLink size={16} />}
      </a>
    </div>
  );

  const handleOptionClick = async (option) => {
    setMessages((prev) => [...prev, { role: "user", content: option.text }]);

    if (option.isBack) {
      setCurrentStep("initial");
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t.welcome.initial,
          showOptions: true,
        },
      ]);
      return;
    }

    if (currentStep === "initial") {
      if (option.id === "1") {
        setCurrentStep("vehicleType");
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t.vehicleTypes.prompt,
            showOptions: true,
            optionType: "vehicleType",
          },
        ]);
      } else if (option.id === "2") {
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t.aircoSection.title,
            links: [
              {
                url: "https://www.aankoopvanautos.be/goedkoop-airco-bijvullen/",
                text: t.aircoSection.buttonText,
                icon: "ExternalLink",
              },
            ],
          },
        ]);

        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t.conversation.moreQuestions,
              showOptions: true,
              optionType: "askMore",
            },
          ]);
          scrollToBottom(); // Forcer le scroll après l'ajout
        }, 1000);
      } else if (option.id === "3") {
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t.conversation.whatToKnow,
            allowInput: true,
          },
        ]);
      }
    } else if (currentStep === "vehicleType") {
      const vehicleType = option.id;

      setCurrentStep("askMore");

    if (t.vehicleTypes[vehicleType]) {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: `${t.forms.title} ${t.vehicleTypes[vehicleType]}`,
          links: [
            {
              url: t.forms.url,
              text: t.forms.buttonText,
              icon: "ExternalLink",
            },
          ],
        },
      ]);
    } else {
      console.error("Type de véhicule non reconnu :", vehicleType);
    }

      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t.conversation.moreQuestions,
            showOptions: true,
            optionType: "askMore",
          },
        ]);
      }, 1000);
    } else if (option.id === "yes") {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t.conversation.listening,
          showOptions: false,
          allowInput: true,
        },
      ]);
    } else if (option.id === "no") {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t.conversation.goodbye,
          showOptions: false,
        },
      ]);

      scrollToBottom();

      // Démarrer le timer après le message de fin
      startResetTimer();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputText.trim()) return;

    // Réinitialiser le timer si l'utilisateur est actif
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      setCountdown(null);
      setMessages((prev) => prev.filter((msg) => !msg.isTimer));
    }

    const userMessage = inputText.trim();
    setMessages((prev) => [...prev, { role: "user", content: userMessage }]);
    setInputText("");

    // Vérifier si le message contient des références à des fichiers ou uploads
    if (!validateResponse(userMessage)) {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t.conversation.textOnly,
          allowInput: true,
        },
      ]);
      return;
    }

    try {
      setIsLoading(true);

      // log pour vérifier l'URL de l'API et le message à envoyer
      console.log("URL de l'API :", `${API_URL}/api/chat/message`);
      console.log("Message envoyé :", userMessage);

      if (
        userMessage.toLowerCase().includes("waar") ||
        userMessage.toLowerCase().includes("adres") ||
        userMessage.toLowerCase().includes("locatie")
      ) {
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t.location.address,
            showOptions: false,
            allowInput: true,
          },
        ]);

        // Ajouter "Heb je nog vragen?" après un délai
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t.conversation.moreQuestions,
              showOptions: true,
              optionType: "askMore",
            },
          ]);
        }, 1000);
      } else if (
        userMessage.toLowerCase().includes("openingsuren") ||
        userMessage.toLowerCase().includes("open")
      ) {
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t.hours.schedule,
            showOptions: false,
            allowInput: true,
          },
        ]);

        // Ajouter "Heb je nog vragen?" après un délai
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t.conversation.moreQuestions,
              showOptions: true,
              optionType: "askMore",
            },
          ]);
        }, 1000);
      } else {
        const response = await fetch(`${API_URL}/api/chat/message`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: userMessage,
            language: "detect",
          }),
        });

        // Loguer la réponse complète avant de la traiter
        console.log("Réponse brute :", response);

        const data = await response.json();

        // Loguer la donnée brute reçue
        console.log("Donnée reçue du serveur :", data);

        // Nettoyer la réponse en enlevant les références source
        const cleanedContent = data.content.replace(/【.*?】/g, "").trim();

        // Ajouter un log pour vérifier la réponse nettoyée
        console.log("Réponse nettoyée :", cleanedContent);

        // Exemple d'utilisation :
        if (!validateResponse(cleanedContent)) {
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t.conversation.serviceOnly,
              allowInput: true,
            },
          ]);
        } else {
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: cleanedContent,
              allowInput: true,
            },
          ]);
        }

        // Puis ajouter "Heb je nog vragen?" après un délai
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t.conversation.moreQuestions,
              showOptions: true,
              optionType: "askMore",
              allowInput: true, // Ajoutez cette ligne
            },
          ]);
        }, 1000);
      }
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t.conversation.error,
          showOptions: false,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  // Nettoyer le timer quand le composant est démonté
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="w-full max-w-md bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="bg-blue-600 text-white p-4 flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Car className="w-6 h-6" />
          <div>
            <h1 className="font-semibold">AankoopVanAutos Assistant</h1>
            <p className="text-sm text-blue-100">Online 24/7</p>
          </div>
        </div>
        <div className="flex gap-2">
          {["nl", "fr", "en"].map((lang) => (
            <button
              key={lang}
              onClick={() => handleLanguageChange(lang)}
              className={`px-2 py-1 text-xs rounded ${
                currentLang === lang
                  ? "bg-white text-blue-600"
                  : "bg-blue-500 hover:bg-blue-400 text-white"
              }`}
            >
              {lang.toUpperCase()}
            </button>
          ))}
        </div>
      </div>

      <div className="h-[60vh] mdh-[400px] overflow-y-auto p-4 space-y-4">
        {messages.map((msg, index) => (
          <div key={index} className="space-y-2">
            <div
              className={`flex ${
                msg.role === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`rounded-lg p-3 max-w-[85%] ${
                  msg.role === "user"
                    ? "bg-blue-600 text-white"
                    : msg.isTimer
                    ? "bg-yellow-100 text-gray-700"
                    : "bg-gray-100"
                }`}
              >
                {msg.content}
                {msg.isTimer && countdown > 0 && (
                  <div className="text-sm text-gray-500 mt-1">
                    {`Nog ${Math.floor(countdown / 60)}:${(countdown % 60)
                      .toString()
                      .padStart(2, "0")} minuten`}
                  </div>
                )}
                {msg.links?.map((link, i) => (
                  <LinkWithButton key={i} {...link} />
                ))}
              </div>
            </div>
            {msg.showOptions &&
              options[msg.optionType || currentStep] &&
              !msg.allowInput && (
                <div className="flex flex-wrap gap-2 mt-3">
                  {options[msg.optionType || currentStep].map((option) => (
                    <button
                      key={option.id}
                      onClick={() => handleOptionClick(option)}
                      className={`px-4 py-2 rounded-full transition-colors ${
                        option.id === "yes"
                          ? "bg-blue-600 text-white hover:bg-blue-700"
                          : option.id === "no"
                          ? "bg-gray-300 hover:bg-gray-400"
                          : option.isBack
                          ? "bg-gray-200 hover:bg-gray-300"
                          : "bg-blue-600 text-white hover:bg-blue-700"
                      }`}
                    >
                      {t[option.text] || option.text}
                    </button>
                  ))}
                </div>
              )}
          </div>
        ))}
        {isLoading && <LoadingDots />}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-4 border-t">
        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder={t.placeholder.input}
            className="flex-1 p-2 border rounded-lg focus:outline-none focus:border-blue-600"
            disabled={!messages[messages.length - 1]?.allowInput}
          />
          <button
            type="submit"
            className={`p-2 rounded-lg ${
              messages[messages.length - 1]?.allowInput
                ? "bg-blue-600 hover:bg-blue-700 text-white"
                : "bg-gray-300 cursor-not-allowed"
            }`}
            disabled={!messages[messages.length - 1]?.allowInput}
          >
            <Send className="w-5 h-5" />
          </button>
        </form>
      </div>

      <div className="bg-gray-50 px-4 py-2 text-xs text-gray-500 flex items-center justify-between border-t">
        <div className="flex items-center gap-1">
          <MessageCircle className="w-4 h-4" />
          <span>Powered by MBA Technics</span>
        </div>
        <a
          href="https://aankoopvanautos.be"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-blue-500"
        >
          AankoopVanAutos
        </a>
      </div>
    </div>
  );
};

export default FloatingChatbot;
